/**
 * Copyright 2018, CLOUDPILOTS Software & Consulting GmbH
 *
 * async
 * @default
 *
 */

import dynamic from "react-loadable";

const FourOhThree = dynamic({
  loader: () =>
    import("./index" /* webpackChunkName: "components.pages" */).then(
      m => m.FourOhThree
    ),
  loading: () => "",
});

const FourOhFour = dynamic({
  loader: () =>
    import("./index" /* webpackChunkName: "components.pages" */).then(
      m => m.FourOhFour
    ),
  loading: () => "",
});

const PageHeader = dynamic({
  loader: () =>
    import("./index" /* webpackChunkName: "components.pages" */).then(
      m => m.PageHeader
    ),
  loading: () => "",
});

const PageLoader = dynamic({
  loader: () =>
    import("./index" /* webpackChunkName: "components.pages" */).then(
      m => m.PageLoader
    ),
  loading: () => "",
});

export { FourOhFour, FourOhThree, PageHeader, PageLoader };
