/**
 * Copyright 2018, CLOUDPILOTS Software & Consulting GmbH
 *
 * React entry point for react-app
 * @default
 *
 */

import React from "react";
import ReactDOM from "react-dom";
import Loadable from "react-loadable";
import { PageLoader } from "./components/library/pages/async";
import "./index.css";

const App = Loadable({
  loader: () => import("./App" /* webpackChunkName: "app" */),
  loading: () => <PageLoader isLoading hasColor isFixed />,
});

ReactDOM.render(<App />, document.querySelector("#root"));
